<template>
	<p>访问路径不存在</p>
</template>

<script>
    export default {
        name: 'NotFound'
    }
</script>

<style scoped>

</style>
